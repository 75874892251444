// Roboto font
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin-ext');

// Lovegivr variables
@import "lovegivr-variables";

// Importing boostrap
@import "bootstrap/scss/bootstrap";

// Lovegivr mixins
@import "lovegivr-mixins";

// Lovegivr global styles
@import "lovegivr-globals";

// Lovegivr components library
@import "lovegivr-components";

// Lovegivr overrides (bootstrap extensions, etc)
@import "lovegivr-overrides";

// LoveGivr iconfont
@import "assets/fonts/lovegivr-iconfont/style.css";

// Flaticon UICons
@import url("https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-bold-straight/css/uicons-bold-straight.css");
@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-solid-straight/css/uicons-solid-straight.css");
@import url("https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-regular-straight/css/uicons-regular-straight.css");
@import url("https://cdn-uicons.flaticon.com/uicons-brands/css/uicons-brands.css");

// ~ ng2-ion-range-slider

// sweetalert2
@import "sweetalert2/dist/sweetalert2.min.css";
@import "assets/style/sweetalert.lovegivr.skin";

// codemirror
@import "codemirror/lib/codemirror.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
