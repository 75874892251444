@font-face {
  font-family: 'lovegivr';
  src:  url('lovegivr.eot?7wj4sk');
  src:  url('lovegivr.eot?7wj4sk#iefix') format('embedded-opentype'),
    url('lovegivr.ttf?7wj4sk') format('truetype'),
    url('lovegivr.woff?7wj4sk') format('woff'),
    url('lovegivr.svg?7wj4sk#lovegivr') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.lovegivr {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lovegivr' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lov-user-icon:before {
  content: "\e90c";
}
.lov-back-arrow:before {
  content: "\e900";
}
.lov-burger-menu:before {
  content: "\e901";
}
.lov-close:before {
  content: "\e902";
}
.lov-drop-down-arrow:before {
  content: "\e903";
}
.lov-heart:before {
  content: "\e904";
}
.lov-search-glass:before {
  content: "\e906";
}
.lov-share:before {
  content: "\e907";
}
.lov-speech-bubble:before {
  content: "\e908";
}
.lov-star:before {
  content: "\e909";
}
.lov-trolley:before {
  content: "\e90a";
}
.lov-wishlist:before {
  content: "\e90b";
}
