@import "lovegivr-variables";

html {
	font-size: 93.75%;
}


// scrollbars
::-webkit-scrollbar {

	width: 6px;
	height: 6px;
	background: white;

	z-index: 9990;
}

::-webkit-scrollbar-thumb {
	background: rgba(0,0,0,.5);
}

// base color
html {
	-ms-overflow-style: -ms-autohiding-scrollbar;
	font-family: $font-family-base;

	a {
		text-decoration: none;
	}
}

body {
	background-color: $content-background;
}

// remove outline for inputs and buttons in safari/chrome
textarea, input, button, select {
	outline: none !important;
}


/*Modals*/
.modal {
	max-width: 100vw !important;
	z-index: 9991;
}

.modal-open .modal-backdrop {

	z-index: 9900 !important;
	opacity: .8 !important;
	background: #6a6a6aad;
	//opacity: .5;
	user-select: none;
	//background: rgb(100,100,100);

	// sexy filter for the future
	// https://caniuse.com/#search=backdrop-filter
	//backdrop-filter: blur(3px);
	//-webkit-backdrop-filter: blur(3px);

	&:after {
		content: "\d7";
		color: white;

		// hide X button
		display: none!important;

		position: fixed;
		right: 15px;
		top: 15px;

		font-size: 40px;
		line-height: 25px;
	}

}

.modal.fade {
	@include mobile {
		animation-name: modal-popup;
		animation-duration: .3s;
		animation-timing-function: $easing-expo;

	}
}

@keyframes modal-popup {
	0% {
		transform: translateY(100px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}


.modal.show .modal-dialog {
	@include mobile {
		margin: 0;
		width: 100%;
		height: 100vh;
		top: 0;
		opacity: 1;
	}
}

.modal-dialog .modal-content{

	box-shadow: 0 0 10px -2px $pink;
	border-radius: 6px;
	overflow: hidden;
	border: none;

	@include mobile {
		box-shadow: none;
		border-radius: 0;
		height: 100%;
		overflow: visible;
	}

	.modal-header, .modal-body, .modal-footer{
		border-bottom: none;
		//padding-left: 25px;
		//padding-right: 25px;
	}

	.modal-header{
		h1{
			font-size: 1.5rem;
			margin-bottom: 0;
			margin-top: 1rem;
		}

		button {
			outline: none !important;
		}
	}


	button.close {
		float: right;
	}

	.modal-footer{

		background: $content-background-bright;
		border-top: none;

		> :not(:first-child), > :not(:last-child){
			margin: 0;
		}

		p{
			margin-bottom: 5px !important;
		}

	}

}

.modal-backdrop{
	transition: none !important;
}

.modal-body, .modal-header{

	h1{
		font-size: 36px;
		font-weight: 300;
		text-align: center;

		padding-top: .7em;
		padding-bottom: .7em;
	}

}

// extra karge modal
.modal-xl .modal-dialog{
	max-width: 1000px;
}

.modal-xxl .modal-dialog{
	max-width: 90vw;

	@include mobile {
		max-width: inherit;
	}
}

// adding a stronger selector
body{

	// special button colours for facebook and twitter
	.btn-facebook{
		background: $color-facebook;
		border-color: $color-facebook;
		color: $white !important;

		&:hover{
			background: lighten($color-facebook, 10%);
			border-color: lighten($color-facebook, 10%);
		}

		&:focus {
			box-shadow: none !important;
			background: lighten($color-facebook, 10%);
			border-color: lighten($color-facebook, 10%);
		}
	}

	.btn-twitter{
		background: $color-twitter;
		border-color: $color-twitter;

		&:hover{
			background: lighten($color-twitter, 10%);
			border-color: lighten($color-twitter, 10%);
		}
	}

}

.cur-pointer{
	cursor: pointer;
	outline: none !important;
}

.framed{
	border: 1px solid $gray-900;
	padding: .1em .3em;
}

/*Checkbox global style*/
input[type=checkbox] {
	position: relative;

	cursor: pointer;

	&:before {
		transition: transform .4s cubic-bezier(.45,1.8,.5,.75);
		transform: rotate(-45deg) scale(0, 0);

		content: "";

		position: absolute;
		left: 3px;
		top: 2px;
		z-index: 1;

		width: 12px;
		height: 6px;

		border: 2px solid $pink;
		border-top-style: none;
		border-right-style: none;
	}

	&:checked {
		&:before {
			transform: rotate(-45deg) scale(1, 1);
		}
	}

	&:after {
		content: "";

		position: absolute;
		top: -2px;
		left: 0;

		width: 16px;
		height: 16px;

		background: #fff;

		border: 2px solid $gray-200;

		cursor: pointer;
	}
}

// Thick button
.btn-thick{
	padding: 1em !important;
}

.btn:focus {
	box-shadow: none!important;
}

.dlt {
	margin-left: .5rem!important;
}

body select.custom-select {
	height: 3rem;
}

// Loading indicator logo
.loading-indicator-logo {

	max-width: 60px;
	min-width: 60px;

	.ani {
		fill: $pink;
	}
}


.carousel:focus {
	outline: none !important;
}

.carousel-control-prev,
.carousel-control-next {
	// height: 80% !important;
	margin-top: 10rem;
	margin-bottom: 10rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	// background-image: none!important;
	// filter: none!important;
	// height: 3rem!important;
}
//
//.carousel-control-next-icon:after {
//	content: '>>';
//	font-family: $font-family-monospace;
//	font-size: 3.75rem;
//	font-weight: bold;
//	color: $pink;
//}
//
//.carousel-control-prev-icon:after {
//	content: '<<';
//	font-family: $font-family-monospace;
//	font-size: 3.75rem;
//	font-weight: bold;
//	color: $pink;
//}

.carousel-indicators {
	display: none!important;
}


// new
.nui-label {
	text-transform: uppercase;
	text-align: right;
	font-size: .85rem;
	color: $pink-strong;
	font-weight: 500;
	border-bottom: 1px solid $pink-strong;
	border-image: linear-gradient(270deg, #D31148, #ca6f8480) 4;
}

[data-nui-tooltip]:hover {

	position: relative;

	&:after {
		content: attr(data-nui-tooltip);
		position: absolute;
		top: 0;
		left: 0;
		display: block;

		padding: .5rem;
		border-radius: 2px;
		font-size: 1rem;

		background: rgba(0,0,0,0.8);
		color: white;
		box-sizing: content-box;
		transform: translate(0, -130%);
		white-space: nowrap;

	}
}

.nui {

	&.panel-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: baseline;

		h1 {
			color: #4B4B4B;
			font-size: 1.75rem;
			font-weight: bold;

			margin-top: 1em;
			margin-bottom: 1em;

			@include mobile {
				font-size: 1.25rem;
			}
		}
	}

	&.dashboard-panel {
		background: white;
		border-radius: 4px;

		background: $white;
		box-shadow: 0 3px 6px rgba(0,0,0,.07);
		//box-shadow: $box-shadow-material-100;
		box-sizing: border-box;

		border-bottom: 1px solid $content-background-bright;

		margin: 0;
		padding: 1rem;

		margin-bottom: 1rem;

		h2 {
			font-weight: bold;
			font-size: 1rem;
			color: #707070;
			margin-bottom: 1em;
		}

		h3 {
			font-weight: bold;
			font-size: 1rem;
			color: #B5B5B5;
			margin: 0;
		}
	}

}

.nui-button {
	background: $pink;
	color: white;
	border: none;

	padding: .25rem 1rem;
	font-weight: 500;
	border-radius: 3px;

	&:hover {
		background: lighten($pink, 7.5%);
	}

	&:focus {
		outline: none !important;
	}

	&:disabled {
		background: #d2adb8;
	}

	// secondary style
	&.secondary {
		background: #DFDFDF;
		color: $black;

		&:hover {
			background: darken(#DFDFDF, 7.5%);
		}
	}

	&.round {
		padding: .25rem .5rem;
	}

	i.las, i.fas, i.fi {
		//font-size: 1.1rem;
		//line-height: 0;
		text-align: center;
		vertical-align: middle;
	}
}

.nui-dialog {

	overflow: auto;
	box-shadow: 0 0 10px -2px $pink;
	border-radius: 6px;
	border: none;

	&.no-padding {
		padding: 0;
	}

	&::backdrop {
		opacity: .8 !important;
		background: #6a6a6aad;
	}

	&[open] {
		animation: nuiDialogFadeIn 0.4s ease normal;
	}

	@keyframes nuiDialogFadeIn{
		from {
			opacity: 0;
			transform: translateY(50px);
		}
		to {

			transform: translateY(0);
			opacity: 1;
		}
	}

	header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: baseline;
		gap: 2rem;
	}

	p.title {
		font-weight: 500;
	}


}
