@import "lovegivr-variables";
@import "lovegivr-mixins";

.nui {

	// page headline
	&.headline {
		text-align: center;
		margin-top: 2rem;

		h1 {
			color: $pink;
			font-weight: bold;
			margin-bottom: 0;
			font-size: 4.5em;

			@include mobile {
				font-size: 2.5rem;
			}
		}

		&.medium {
			h1 {
				font-size: 3.5rem;
				margin-bottom: 3rem;

				@include mobile {
					font-size: 2.5rem;
				}
			}
		}

		p {
			color: #99a4ac;
		}
	}

	&.button {
		@include nui-button();

		&.orange {
			background: #e8561d;
			&:hover {
				background: #ce4d1a;
			}
		}
	}

	&.input-container {
		display: flex;
		flex-direction: column;

		margin: 0 0 1.5em 0;
		width: 100%;

		&.medium-sized {
			width: 70%;
		}

		&.small-sized {
			width: 40%;
		}

		&.additional-gap-between {
			margin: 2em 0 1.5em 0;
		}

		.label-container {
			display: flex;
			align-items: center;

			margin-bottom: .1em;

			label {
				color: #7d7d7d;
				font-weight: lighter;

				margin-bottom: 0;
			}

			.invalid-feedback-container {
				color: red;
				font-weight: bold;
				font-size: 0.9em;
			}

		}

		.input-with-icon {
			display: flex;
			width: 100%;
			background: #DFDFDF;
			border-radius: 3px;

			border-bottom: 2px solid #DFDFDF;

			i {
				border-left: none;
				font-size: 1em;

				display: flex;
				align-items: center;
				justify-content: center;

				color: #7d7d7d;

				padding: 0 .5rem;
				font-style: normal;

				cursor: pointer;
			}

			input {
				border-right: none!important;
				background: transparent;

				width: 100%;

				&:focus {
					border-bottom-color: transparent;
				}
			}

			&:focus-within {
				border-bottom: 2px solid $pink-light;
			}
		}

		input {
			padding: .25em .5em;
			border: none;
			background: #DFDFDF;
			border-bottom: 2px solid #DFDFDF;
			border-radius: 3px;

			&.is-invalid {
				border-bottom: 2px solid red;
			}
		}

		input:focus {
			border-bottom: 2px solid $pink-light;
		}

		.additional-info-container {
			margin: .25em 0 0 0;
			font-size: 0.8em;
			color: #7d7d7d;
		}

	}


}
