@import "bootstrap/scss/functions";
@import "bootstrap/scss/_variables";
@import 'bootstrap/scss/mixins/breakpoints';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@800&display=swap');
// new
// Breakpoints
$phone-breakpoint: 640px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$large-screen-breakpoint: 1200px;


// old
// Options
$enable-rounded: true;

// Fonts
$font-family-sans-serif: "Noto Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

// Colors
$pink: #DA3D6A;
$pink-strong: #D31148;
$pink-light: #EA4062;
$pink-lighter: #EA3F63;
$pink-lightest: #EA3F6217;
$yellow: #EFC727;
$gray: #CCCCCC;
$dark: #252525;
$darker: #1d1d1d;
$light-green: #00A827;
// brand colors
$theme-colors: (
	primary: $pink,
	secondary: $gray-600,
	success: $green,
	info: $cyan,
	warning: $yellow,
	danger: $red,
	light: $gray-100,
	dark: $dark
);

$settings-page-font-color: #565656;

$color-facebook: #3c5a94;
$color-twitter: #2aa4f1;

// links
$link-color: black;
$link-hover-color: $pink;

// Content area
$content-background: #F5F5F5;

// Bright content area
$content-background-bright: #F6F6F6;

// Z-indexes
$zindex-modal: 9901;
$zindex-tooltip: 9900;

// input colors
$input-border-color: white;
$input-focus-border-color: #CCC;

// input and button paddings
$input-btn-padding-y: .75rem;
$input-btn-padding-x: 1rem;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;

// input addon colors
$input-group-addon-bg: white;
$input-group-addon-border-color: white;

// input placeholder color
$input-placeholder-color: #AAA;

// custom select variables
$custom-select-padding-y: $input-btn-padding-y;
$custom-select-padding-x: $input-btn-padding-x;
$custom-select-border-color: $input-border-color;
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-height: 3rem;

// border radius
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

// border-radius overrides
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;
$custom-checkbox-indicator-border-radius: $border-radius;
$nav-tabs-border-radius: $border-radius;
$nav-pills-border-radius: $border-radius;
$navbar-toggler-border-radius: $btn-border-radius;
$card-border-radius: $border-radius;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$list-group-border-radius: $border-radius;
$thumbnail-border-radius: $border-radius;
$dropdown-border-radius: $border-radius;

// Input border-radius
$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius;
$custom-select-border-radius: $input-border-radius;
$custom-file-border-radius: $input-border-radius;
$alert-border-radius: $input-border-radius;
$progress-border-radius: $input-border-radius;

// Remove blue highlight
$input-focus-box-shadow: none;

// box shadows
$input-btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-active-box-shadow: none;
$input-box-shadow: none;
$dropdown-box-shadow: none;

// Sidebars
$sidebar-width: 280px;
$sidebar-md-width: 180px;
$sidebar-background: #F6F4F6;
$sidebar-background-bright: #FCFCFC;

// Common
$easing-expo: cubic-bezier(0.645, 0.045, 0.355, 1);

// Nav-bar
$navbar-background: #FFFFFF;
$navbar-height: 70px;
$navbar-height-sm: 50px;

// Drawer menu
$drawer-background: white;
$drawer-border-color: #AAA;
$drawer-width: 450px;
$drawer-z-index: 7000;
$drawer-padding-top: $navbar-height;
$drawer-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 16px -5px rgba(0, 0, 0, 0.5);
$drawer-animation-speed: .5s;

// Product page
$product-z-index: $drawer-z-index - 100;

$drawer-item-border-color: #BBB;

// User profile card width
$user-profile-card-width: 450px;

// Bouncy border animation color
$bouncy-border-color-start: white;
$bouncy-border-color-end: $pink;

// Feed breakpont
$feed-4-col-breakpoint: 1700px;

// Liked feed
$liked-feed-item-width: 17vw;
$liked-feed-item-width-min: 230px;

// Material-like box-shadow
$box-shadow-material-100: 0 2px 5px -2px rgba(50,50,50,.2);
$box-shadow-material-200: 0 5px 7px 0 rgba(50,50,50,.2);
