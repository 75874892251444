@import "lovegivr-variables";

@keyframes bouncyBorderAnimation {
	0%{ box-shadow: 0 0 0 2px $bouncy-border-color-start; opacity:1;}
	25%{ box-shadow: 0 0 0 0 $bouncy-border-color-start; opacity:1;}
	50%{ box-shadow: 0 0 0 7px $bouncy-border-color-end; opacity:1; }
	75%{ box-shadow: 0 0 0 3px $bouncy-border-color-end; opacity:1;}
	100%{ box-shadow: 0 0 0 5px $bouncy-border-color-end; opacity:1;}
}

@keyframes bouncyBorderAnimationThin {
	0%{ box-shadow: 0 0 0 1px $bouncy-border-color-start; opacity:1;}
	25%{ box-shadow: 0 0 0 0 $bouncy-border-color-start; opacity:1;}
	50%{ box-shadow: 0 0 0 4px $bouncy-border-color-end; opacity:1; }
	75%{ box-shadow: 0 0 0 2px $bouncy-border-color-end; opacity:1;}
	100%{ box-shadow: 0 0 0 3px $bouncy-border-color-end; opacity:1;}
}
