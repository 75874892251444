@import "../../lovegivr-variables";

body {

	// Sweetalert z-index
	.swal2-container {
		z-index: 9999 !important;
	}

	.swal2-popup {
		border-radius: 0;

		.swal2-title {
			font-weight: normal;
			font-size: 1.2rem;
			font-family: $font-family-base;
		}

		.swal2-styled.swal2-confirm {

			background: $pink-strong;

			&:focus {
				background: lighten($pink-strong, 10%);
			}
		}

		.swal2-styled.swal2-cancel, .swal2-styled.swal2-confirm {
			border-radius: 0;
			text-transform: uppercase;
			padding: 0.5rem 2rem;

			&:focus {
				box-shadow: none;
			}
		}
	}



}
