@import "lovegivr-variables";
@import "lovegivr-animations";
@import '../node_modules/bootstrap/scss/mixins/breakpoints';

// new
@mixin comment-input() {
	margin-top: 1rem;

	//write a comment input field
	.input-group {

		border: 1px solid white;
		transition: .3s $easing-expo;
		box-shadow: 0 3px 27px -14px #42424266;

		border-radius: 2px;

		#comment-input {
			order: 2;

			border: none;

			&:valid + .input-group-addon {
				color: $pink;
			}
		}

		.input-group-addon {
			order: 1;

			padding-left: .5rem;
			display: flex;
			justify-content: center;
			align-items: center;

			color: $gray-600;
			background: white;
			border: none;
			padding-right: 0;
			transition: .3s $easing-expo;
		}

	}

	.send-hint {
		color: $gray-700;
		font-size: 12px;

		margin-top: -1em;
		opacity: 0;

		transition: .3s $easing-expo;
	}

	// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within
	&:focus-within {

		.input-group {
			border-color: #d3114873;
			box-shadow: 0 3px 20px -9px #de0f56;
		}

		.send-hint {
			margin-top: 0;
			opacity: 1;
		}

	}
}

// Breakpoints
@mixin mobile {
	@media (max-width: #{$tablet-breakpoint - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-breakpoint}) and (max-width: #{$desktop-breakpoint - 1px}) {
		@content;
	}
}

@mixin mobile-and-tablet {
	@media (max-width: #{$desktop-breakpoint - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-breakpoint}) {
		@content;
	}
}

@mixin tablet-and-desktop {
	@media (min-width: #{$tablet-breakpoint}) {
		@content;
	}
}

@mixin masonry {

	//overflow-x: scroll;
	//overflow-x: hidden;
	width: 100%;

	max-width: 1200px;
	margin: 40px auto 0;

	box-sizing: border-box;

	container-type: inline-size;
	container-name: masonry;

	// 1 displayed column
	::ng-deep .item {

		// always take up the full space
		width: 100% !important;


		@container masonry (min-width: 640px) {
			// always take up the full space
			width: 50% !important;

			// remove padding on this screen size
			padding-bottom: 0 !important;
			padding-top: 0 !important;
		}

		@container masonry (min-width: 900px) {
			// always take up the full space
			width: calc(100% / 3)!important;
		}

	}
	//::ng-deep &.columns-1 .item {
	//
	//	// always take up the full space
	//	width: 100% !important;
	//
	//	// remove padding on this screen size
	//	padding-bottom: 0 !important;
	//	padding-top: 0 !important;
	//}
	//
	//
	//// 2 displayed columns
	//::ng-deep &.columns-2 .item {
	//
	//	// take up half of the space
	//	width: 50%;
	//
	//	// remove padding on this screen size
	//	padding-bottom: 0 !important;
	//	padding-top: 0 !important;
	//
	//	&.big {
	//		width: 50%;
	//	}
	//
	//	&.bigger {
	//		width: 100%;
	//	}
	//}
	//
	//// 3 displayed columns
	//::ng-deep &.columns-3 .item {
	//
	//	width: calc(100% / 3);
	//
	//	&.big {
	//		width: calc(200% / 3);
	//	}
	//
	//	&.bigger {
	//		width: 100%;
	//	}
	//
	//}
	//
	//// 4 displayed columns
	//::ng-deep &.columns-4 .item {
	//
	//	width: 25%;
	//
	//	&.big {
	//		width: 50%;
	//	}
	//
	//	&.bigger {
	//		width: 75%;
	//	}
	//
	//}

}

.empty-text {
	color: #484848;
	padding-top: 5rem;

	h1 {
		font-size: 500%;
		margin-bottom: .5em;
	}

	p {
		font-size: 16px;
	}
}


// OLD

// accordion without frames
@mixin transparent-accordion() {
	.card {

		background: transparent;
		border: none;

		.card-header {
			background: transparent;
			border: none;

			padding: 1em 0;

			text-transform: uppercase;

			font-size: 13px;
			font-weight: bold;

			a {
				color: #050505;

			}

			// arrow before it
			&:before {
				content: '\F0DA';
				font-family: "Font Awesome 5 Free";
				width: .7em;
				display: inline-block;
			}

			&.active:before {
				content: '\F0D7';
			}

		}

		.card-body {
			padding-left: 0;
			padding-right: 0;
		}

		// card content
		.card-block {
			border: none;
		}

	}
}

// lovegivr tablists
@mixin navigation-tabs() {

	::ng-deep ul[role=tablist] {

		background: #ffffff;
		padding: .75em;
		border-bottom: 1px solid #D1D1D1;

		position: sticky;
		top: $navbar-height;
		justify-content: center;
		flex-wrap: nowrap;

		overflow-y: auto;

		@include media-breakpoint-down(sm) {
			top: 0;
			justify-content: flex-start;
		}

		z-index: 5000;

		.nav-item {
			font-weight: bold;
			font-size: .9375rem;

			.nav-link {
				color: $gray-600;
				background: transparent;

				border-width: 0;
				white-space: nowrap;

				&.active {
					color: #ffffff;
					border-radius: 60px;
					background: $pink;
				}
			}
		}

	}

	::ng-deep .tab-content {
		padding-bottom: 40px;
		.tab-content-title {
			font-size: 22px;
			font-weight: 300;
			text-align: center;
			margin: 2.5em 0;

			b {
				font-weight: bold;
			}
		}
	}

}

@mixin validated-input() {

	input:valid + .invalid-feedback {
		display: none;
	}

	.custom-invalid {
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
	}

}


@mixin dark-social-buttons-overlay() {

	.social-buttons {
		position: absolute;
		z-index: 100;

		top: 0;
		left: 0;

		::ng-deep .social-button {

			background: #FFFFFFCC;

			border-radius: 50%;

			display: flex;
			align-items: center;
			justify-content: center;

			width: 40px;
			height: 40px;

			.number {
				color: white;
				display: none;
			}

			.icon {
				color: #707070;
				margin: 0;
			}
		}
	}

}

@mixin animated-logo() {
	::ng-deep svg {

		.ani {
			transition: all .4s $easing-expo;
			transform-origin: 40.8% 50%;
			transform-box: fill-box;
			//-moz-transform-origin: 1040px 538px;
		}

		.text-love, .icon-heart {
			fill: $pink-strong;
		}

		.icon-g {
			transform: rotate(0deg);
			fill: black;
		}

		.text-love {

		}

		.icon-heart {
			transform: scale(0);
		}

		&:hover {
			.text-love, .text-ivr {
				fill: black;
				opacity: .8;
			}

			.icon-g {
				fill: $pink-strong;
				transform: rotate(-360deg);
			}

			.icon-heart {
				fill: $pink-strong;
				transform: scale(1);
			}
		}

	}
}

// bouncy border animation
@mixin bouncyBorder() {

	img {
		background-color: $bouncy-border-color-start;
		box-shadow: 0 0 0 2px $bouncy-border-color-start;
		transition: box-shadow .2s $easing-expo;
	}

	&:hover img {
		cursor: pointer;
		animation: bouncyBorderAnimation .4s linear;
		box-shadow: 0 0 0 5px $bouncy-border-color-end;
	}
}

// bouncy border animation
@mixin bouncyBorderThin() {

	img {
		background-color: $bouncy-border-color-start;
		box-shadow: 0 0 0 1px $bouncy-border-color-start;
		transition: box-shadow .2s $easing-expo;
	}

	&:hover img {
		cursor: pointer;
		animation: bouncyBorderAnimationThin .4s linear;
		box-shadow: 0 0 0 3px $bouncy-border-color-end;
	}
}

@mixin fixed-position-sidebar() {

	position: fixed;
	top: $navbar-height;
	bottom: 0;

	@include media-breakpoint-down(sm) {
		top: $navbar-height-sm;
	}

}

@mixin scrollable-sidebar() {

	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 0;
	}
}

@mixin feed-item-with-image() {

	width: 100%;

	box-sizing: border-box;

	:focus {
		outline: none;
	}

	.item-image-container {

		@apply shadow-sm;
		@apply rounded-lg;

		position: relative;
		overflow: hidden;
		background: white;

		border-radius: 8px;
		margin-bottom: .3rem;

		transition: box-shadow .3s ease;

		img {
			width: 100%;
			min-height: 200px;
			max-height: calc(80vh - #{$navbar-height});
			object-fit: cover;
			object-position: center;

			transition: transform .3s;

			//&:hover {
			//	transform: scale(1.05);
			//}
		}

		// dark social buttons
		@include dark-social-buttons-overlay();

	}

	.item-title {

		display: inline-flex;

		align-items: center;

		padding-top: .5rem;
		padding-bottom: 0;
		margin-bottom: .25rem;

	}

	//&:hover .item-image-container {
	//	box-shadow: 0 2px 15px -4px rgb(50 50 50 / 50%), 0 28px 30px -5px rgba(0, 0, 0, 0.05);
	//}

}

// general backdrop that can be used anywhere
@mixin backdrop() {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	display: block;

	background: rgba(100, 100, 100, .3);
}

// sophisticated dropdown menu. Used e.g. for notifications and shopping cart.
@mixin scrollable-dropdown-menu() {

	width: 450px;
	padding: 0;
	box-shadow: 0 0 17px -6px rgba(0, 0, 0, 0.55);

	.dropdown-container {

		display: flex;
		flex-direction: column;

		// TODO RR - noti
		//min-height: 200px;

		> header {

			position: relative;

			padding: .7em 0;
			font-size: 14px;
			font-weight: bold;

			border-bottom: 1px solid $gray-300;

			.dropdown-loading-indicator {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}

		> main {
			flex-grow: 1;

			max-height: 500px;
			overflow: auto;

			.dropdown-item {
				border-bottom: 1px solid $gray-200;
				padding: 0.25rem 1rem;

				&:hover {
					background: $content-background-bright;
					color: inherit;
				}
			}

			.empty-text {
				color: $gray-400;

				p {
					font-size: 14px;
				}
			}

		}

		> footer {

			* {
				width: 100%;
				text-align: center;

				padding: .5em;
			}
		}
	}

}

@mixin page-back-button() {
	position: absolute;
	top: calc(15px + #{$navbar-height});
	left: 30px;

	background: #FFFFFFCC;
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 2000;

	cursor: pointer;

	svg {
		height: 2.5rem;
		path {
			fill: black;
		}
	}

	&:hover {
		svg {
			path {
				fill: $pink-strong;
			}
		}
	}

	@include mobile() {

		top: calc(5px + #{$navbar-height-sm});
		left: 10px;

		svg {
			height: 1.5rem;
		}
	}
}

@mixin modal-backdrop() {
	position: fixed;
	top: 0;
	height: 100vh;
	left: 0;
	width: 100vw;

	background: rgba(100, 100, 100, 0.3);

	z-index: 9000;
}

@mixin nui-button() {
	color: white;
	font-weight: 500;
	background: $pink;
	background: linear-gradient(45deg, $pink, #e8561d);
	box-shadow: 0px 13px 40px -15px rgba(218, 61, 61, 0.78);
	border: none;
	border-radius: 2px;

	background-size: 100% 100%;
	transition: all .5s ease;

	padding: 0.75rem 1rem;
	font-size: 1rem;
	line-height: 1.5;

	&:hover {
		background-size: 200% 100%;
		background-position-x: 100%;
		box-shadow: 0px 9px 30px -10px rgba(218, 61, 61, 0.78);
	}

	&:active {
		box-shadow: 0 0 30px -5px rgba(218, 61, 61, 0.78);
	}

	&:disabled,
	[disabled] {
		filter: grayscale(100%);
	}
}

@mixin nui-panel() {
	background: white;
	border-radius: 2px;
	//box-shadow: 0 5px 35px -5px rgba(0, 0, 0, 0.05),
	//0 0 5px rgba(0, 0, 0, 0.12),
	//0 65px 52px -52px rgba(0, 0, 0, 0.27);

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;

	padding: 2em;
}

@mixin nui-page-background($color) {
	background: radial-gradient(circle at bottom, $color, $content-background-bright 50%);
}

@mixin quick-search() {


	display: flex;
	text-align: center;

	position: relative;

	background: #F5F5F5;
	border: 1px solid #E6E6E6;

	border-radius: 0.75rem;
	padding: 0 0.75rem;

	input {
		//min-width: 200px;
		width: 30vw;
		max-width: 450px;
		padding: 4px 0;
		margin: 0 6px;

		background: none;
		border:none;

		font-size: 16px;
		font-weight: 500;

		transition: all 0.3s $easing-expo;

		&::placeholder {
			font-weight: 100;
			color: #A7A7A7;
			transition: all 0.3s $easing-expo
		}

		&:active, &:focus {
			border-bottom-color: $gray-500;
			padding-left: 5px;

			&::placeholder {
				color: $gray-500;
			}

			~ button {
				color: $gray-900;

				&:hover {
					color: $pink;
				}
			}
		}

		&:valid {
			// border-bottom-style: dotted;
			padding-left: 5px;
		}
	}

	button {
		color: #A7A7A7;
		background: none;
		border: none;
		outline: none !important;

		font-size: 1.3rem;
		line-height: 0;
		transition: color 0.3s $easing-expo;

		&:hover {
			color: $gray-900;
		}

	}


}
