@import "lovegivr-variables";


// dark outline buttons have bold text
.btn.btn-outline-dark {
	font-weight: bold;
}

.btn.btn-link {
	color: $black !important;
	font-size: .9rem !important;
	font-weight: bold;
	padding: .5rem!important;

	&:focus, &:hover {
		text-decoration: none!important;
	}
}

// remove borders from input fields except for the bottom one
.form-control {
	border-top-width: 0 !important;
	border-left-width: 0 !important;;
	border-right-width: 0 !important;;
}

// fixing select
body select.form-control:not([size]):not([multiple]) {
	height: 3rem;
}

// icon fixes for font awesome 5 in ngx-editor
.ngx-toolbar .ngx-editor-button {
	.fa-scissors:before {
		content: "\f0c4";
	}
	.fa-files-o:before {
		content: "\f0c5";
	}
	.fa-repeat:before {
		content: "\f01e";
	}
	.fa-chain-broken:before {
		content: "\f127";
	}
	.fa-picture-o:before {
		content: "\f03e";
	}
	.fa-youtube-play:before {
		content: "\f03d";
	}
}

.carousel-control-next-icon, .carousel-control-prev-icon {
	cursor: pointer !important;
	-webkit-filter: drop-shadow( 0 0  5px #000 );
	filter: drop-shadow( 0 0 5px #000 );
}

.wc-date-container {
	border: 1px solid transparent!important;
	border-bottom: 1px solid $gray-400!important;

	height: 3.225rem!important;
	padding: 1rem!important;

	margin-bottom: 1rem!important;

	span {
		color: $black!important;
	}

	i {
		color: $pink!important;
		margin-left: 2rem;
	}
}

.wc-date-popover {
	margin-top: 1rem!important;
}

.wc-banner, .wc-details, .year-dropdown {
	background: $pink!important;
}

.calendar-day {
	color: $pink;

	span {
		&:hover {
			color: white!important;
			background-color: $pink!important;
		}
	}

}

.selected-day {

	span {
		background-color: $pink!important;
		color: white!important;
	}

}

.today {
	span {
		border: 1px dotted $pink!important;
	}
}

.cal-util {

	.ok {
		color: $pink!important;
		&:hover {
			box-shadow: none!important;
			background-color: $pink!important;
			color: white!important;
		}
	}
}
.months-view {
	span {
		&:hover {
			color: $pink!important;
		}
	}
}

.current-month {
	color: $pink!important;
}

.years-list-view {
	span {
		&:hover {
			color: $pink!important;
		}
	}
}

.current-year {
	color: $pink!important;
}

.time-view {
	border-top: none!important;

	.time {
		color: $pink!important;

		input {
			color: $pink!important;
		}
	}

	.cuppa-btn-group {

		// TODO ????
		//button {
		//	&.active {
		//		color: white!important;
		//		background: $pink!important;
		//	}
		//}

		.button {
			border-radius: 0!important;
			border: 1px solid $pink!important;
			color: $pink!important;
		}

	}

	.time-view-btn {
		.button {
			border: none!important;
			border-radius: 0!important;
			background-color: $pink!important;
			color: white!important;
		}
	}
}

.time {
	cursor: pointer!important;
}

:root {
	--bs-breakpoint-xs: 0;
	--bs-breakpoint-sm: 640px;
	--bs-breakpoint-md: 768px;
	--bs-breakpoint-lg: 1024px;
	--bs-breakpoint-xl: 1280px;
	--bs-breakpoint-xxl: 1536px;
}
